import axios, { AxiosResponse } from 'axios';

export type CustomErrorType = {
  code: string;
  message: string;
  status: number;
};

const baseUrl = process.env.REACT_APP_SERVER_URL;
console.log(baseUrl);
//TODO: version은 나중에 api요청할때 넣게끔 수정해야함
const instance = axios.create({ baseURL: `${baseUrl}/api/v1/`, timeout: 3000 });
// Axios request interceptor
instance.interceptors.request.use(
  function (config) {
    // console.log('Starting Request', {
    //   method: config.method,
    //   url: config.url,
    //   headers: config.headers,
    //   data: config.data,
    // });

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// Axios response interceptor
instance.interceptors.response.use(
  function (response) {
    // console.log('Response:', {
    //   status: response.status,
    //   data: response.data,
    // });

    return response;
  },
  function (error) {
    return Promise.reject(error.response.data);
  },
);

// async function validateToken(tokenInfo: TokenInfo) {
//   if (tokenInfo.refreshTokenExpiredAt < Date.now()) {
//     throw Error('refresh token is expired');
//   }
//   if (tokenInfo.accessTokenExpiredAt < Date.now()) {
//     const result = await refresh(tokenInfo.refreshToken);
//     store.dispatch(reissueToken(tokenInfo));
//     return result.data;
//   }
//   return tokenInfo;
// }

export function requestGet<T>(
  url: string,
  queryParams: object = {},
  header: object = {},
): Promise<AxiosResponse<T, any>> {
  return instance.get<T>(url, {
    params: queryParams,
    headers: {
      'Content-Type': 'application/json',
      ...header,
    },
  });
}

// export async function requestSecureGet<T>(
//   url: string,
//   tokenInfo: TokenInfo,
//   queryParams: object = {},
//   header: object = {},
// ): Promise<AxiosResponse<T, any>> {
//   const token = await validateToken(tokenInfo);
//   return instance.get<T>(url, {
//     params: queryParams,
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${token.accessToken}`,
//       ...header,
//     },
//   });
// }

export function requestPost<T>(
  url: string,
  data: object,
  header: object = {},
): Promise<AxiosResponse<T, any>> {
  return instance.post<T>(url, data, {
    headers: {
      'Content-Type': 'application/json',
      ...header,
    },
  });
}

// export async function requestSecurePost<T>(
//   url: string,
//   data: object,
//   tokenInfo: TokenInfo,
//   header: object = {},
// ): Promise<AxiosResponse<T, any>> {
//   const token = await validateToken(tokenInfo);

//   return instance.post<T>(url, data, {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${token.accessToken}`,
//       ...header,
//     },
//   });
// }

export function requestPut<T>(
  url: string,
  data: object,
  header: object = {},
): Promise<AxiosResponse<T, any>> {
  return instance.put<T>(url, data, {
    headers: {
      'Content-Type': 'application/json',
      ...header,
    },
  });
}

// export async function requestSecurePut<T>(
//   url: string,
//   data: object,
//   tokenInfo: TokenInfo,
//   header: object = {},
// ): Promise<AxiosResponse<T, any>> {
//   const token = await validateToken(tokenInfo);

//   return instance.put<T>(url, data, {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${token.accessToken}`,
//       ...header,
//     },
//   });
// }

export function requestDelete<T>(
  url: string,
  header: object = {},
): Promise<AxiosResponse<T, any>> {
  return instance.delete<T>(url, {
    headers: {
      'Content-Type': 'application/json',
      ...header,
    },
  });
}

// export async function requestSecureDelete<T>(
//   url: string,
//   tokenInfo: TokenInfo,
//   header: object = {},
// ): Promise<AxiosResponse<T, any>> {
//   const token = await validateToken(tokenInfo);
//   return instance.delete<T>(url, {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${token.accessToken}`,
//       ...header,
//     },
//   });
// }

// export async function fetchImageToBase64(imgUrl: string): Promise<string> {
//   try {
//     const response = await axios.get(imgUrl, { responseType: 'arraybuffer' });
//     const base64String = btoa(
//       String.fromCharCode(...new Uint8Array(response.data)),
//     );

//     return `data:image/png;base64,${base64String}`;
//   } catch (error) {
//     console.error('Failed to fetch or convert image to Base64:', error);
//     throw Error('fail to fetch image');
//   }
// }
