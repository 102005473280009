import React from 'react';
import './check.box.scss';

type Props = {
  isChecked: boolean;
};

const ic_check_path = '/icons/ic_check';

const Checkbox = ({ isChecked }: Props) => {
  return (
    <div className='check-box'>
      {isChecked && (
        <picture>
          <source
            srcSet={`${ic_check_path}@3x.png`}
            media='(min-width:960px)'
          />
          <source
            srcSet={`${ic_check_path}@3x.png`}
            media='(min-width:620px)'
          />
          <img
            width={24}
            height={24}
            src={`${ic_check_path}.png`}
            alt='ic_check'
          />
        </picture>
      )}
    </div>
  );
};

export default Checkbox;
