import React from 'react';
import './delete.complete.scss';

const success_img_path = '/images/img_check.png';

const DeleteAccountComplete = () => {
  return (
    <section className='delete-complete-root'>
      <img className='success-img' src={success_img_path} />
      <h2 className='desc'>회원탈퇴되었습니다</h2>
    </section>
  );
};

export default DeleteAccountComplete;
