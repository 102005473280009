import React, { useState } from 'react';
import './delete.account.scss';
import { signInWithPopup } from 'firebase/auth';
import { requestPost } from 'utils/network';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'components/checkbox/Checkbox';
import { auth, googleProvider } from './firebase';

const kakao_ic_path = '/icons/ic_kakao.svg';
const google_ic_path = '/icons/ic_google.png';

const DeleteAccount = () => {
  const navigate = useNavigate();
  const link = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_LINK}&response_type=code`;

  const [isChecked, setIsChecked] = useState(false);

  const onUnderstandBtnClicked = () => {
    setIsChecked((prev) => !prev);
  };

  const onKakaoLogin = () => {
    if (isChecked === false) return;

    window.location.href = link;
  };

  const onGoogleLogin = async () => {
    if (isChecked === false) return;

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const token = await user.getIdToken();
      await requestPost('auth/google/callback', { token });
      navigate('/meme/delete-account-complete');
    } catch (error) {
      console.error('로그인 실패:', error);
      navigate('/meme/delete-account-failed');
    }
  };

  return (
    <div className='delete-account-root'>
      <section className='notice'>
        <h3 className='notice-title'>주의사항</h3>
        <p className='desc'>
          1. 현재 사용중인 계정 정보는 회원탈퇴 후 복구가 불가능합니다
        </p>
        <p className='desc'>
          2. 탈퇴 후 회원님의 정보는 개인정보처리방침에 따라 관리됩니다
        </p>
        <p className='desc'>3. 등록한 밈은 자동 삭제되지 않습니다</p>
      </section>
      <button className='check-box-wrapper' onClick={onUnderstandBtnClicked}>
        <Checkbox isChecked={isChecked} />
        <p>위의 주의사항을 이해했습니다</p>
      </button>
      <button
        disabled={!isChecked}
        className='kakao-btn'
        onClick={onKakaoLogin}>
        <img src={kakao_ic_path} alt='ic_kakao' className='kakao' />
        카카오계정 탈퇴하기
      </button>
      <button
        disabled={!isChecked}
        className='google-btn'
        onClick={onGoogleLogin}>
        <img
          width={28}
          height={28}
          src={google_ic_path}
          alt='ic_google'
          className='google'
        />
        구글계정 탈퇴하기
      </button>
    </div>
  );
};

export default DeleteAccount;
