import React from 'react';
import './delete.failed.scss';

const failed_img_path = '/images/img_error.png';

const DeleteAccountFailed = () => {
  return (
    <section className='delete-failed-root'>
      <img className='failed-img' src={failed_img_path} />
      <h2 className='desc'>회원탈퇴 중 오류가 발생하였습니다</h2>
    </section>
  );
};

export default DeleteAccountFailed;
