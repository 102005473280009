import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import DeleteAccount from './DeleteAccount';
import DeleteAccountComplete from './DeleteAccountComplete';
import DeleteAccountFailed from './DeleteAccountFailed';

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<Navigate replace to={'meme/delete-account'} />}
        />
        <Route path='meme/delete-account' element={<DeleteAccount />} />
        <Route
          path='meme/delete-account-complete'
          element={<DeleteAccountComplete />}
        />
        <Route
          path='meme/delete-account-failed'
          element={<DeleteAccountFailed />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
